// MainPage.js
import React from 'react';
import HeaderTop from '../components/headertop';
import SponsorsBar from './sponsorsbar';
import '../styles/mainpage.scss';

const MainPage = () => {
  const backgroundStyle = {
    backgroundImage: `url('/images/Background_Pic.png')`,
  };

  return (
    <>
      <HeaderTop />
      <SponsorsBar />
      <div className="main-content" style={backgroundStyle}>
        <div className="background-overlay" />
        <div className="content-wrapper">
          <h1>The Malaysian Warriors Football Club</h1>
          <p className="tagline">The way of the world in Malaysia</p>
          <div className="cta-buttons">
            <span className="primary-button">Do you want to join us?</span>
            <br />
            <a className="secondary-button" href="/contact">Join The Club</a>
          </div>
        </div>
      </div>

      <div className="social-links">
        <h2>Want to know more?</h2>
        <p>New player? No experience? <strong>Welcome!</strong></p>
        <div className="social-items">
          <div className="social-item">
            <span role="img" aria-label="Instagram">📸</span>
            <a href="https://www.instagram.com/warrior_women_afl-malaysia" target="_blank" rel="noopener noreferrer">
              WARRIOR_WOMEN_AFL-MALAYSIA
            </a>
            <br />
            <a href="https://www.instagram.com/malaysian_warriors-afl" target="_blank" rel="noopener noreferrer">
              MALAYSIAN_WARRIORS-AFL
            </a>
          </div>
          <div className="social-item">
            <span role="img" aria-label="Facebook">📘</span>
            <a href="https://www.facebook.com/MalaysianWarriors" target="_blank" rel="noopener noreferrer">
              Malaysian Warriors Australian Rules Football Club
            </a>
          </div>
          <div className="social-item">
            <span role="img" aria-label="Email">✉️</span>
            <a href="mailto:malaysianwarriors@gmail.com">
              malaysianwarriors@gmail.com
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainPage;
